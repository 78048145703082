@import "../common/mixin.scss";
@import "../common/variable.scss";

.child-register {
  table {
    text-align: center;
    border: 1px solid #000;
    border-radius: 12.999px 0px 0px 0px;
    background: white;
    // @apply text-[10px];
    font-size: 10px;

    thead {
      td {
        padding: 10px;
        white-space: nowrap;
        border: 1px solid #000;
        font-weight: 600;
        text-transform: capitalize;
      }
    }
    tbody {
      td {
        padding: 10px;
        white-space: nowrap;
        border: 1px solid #000;
        font-weight: 400;
      }
    }
  }
  // .tableScrolling {
  //   max-height: calc(100dvh - 138px);

  //   @include mq("phone-and-tablet") {
  //     max-height: calc(100dvh - 130px);
  //   }

  //   thead {
  //     position: sticky;
  //     top: 0;
  //     left: 0;
  //     z-index: 99;
  //     box-shadow: 0 3px 5px -3px #000;
  //     td {
  //       background: #fafafa;
  //     }
  //   }
  // }
}
