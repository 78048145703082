@import "rsuite/dist/rsuite.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap");
@import "./mixin.scss";
@import "./variable.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #4c51bf; /* Set the color of the thumb */
  border-radius: 6px; /* Set the border radius of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the track */
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100dvh;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: $color-primary;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}
p + p {
  margin: 0;
}
body:has(.popup-open) {
  overflow: hidden;
}
.body-content:has(.tableScrolling) {
  overflow: hidden;
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}

.p-inter,
.h-inter {
  font-family: "Inter", sans-serif;
}
.p-poppins,
.h-poppins {
  font-family: "Poppins", sans-serif;
}

.input-tag {
  @apply border border-[#758092] rounded-md lg:text-sm text-xs font-medium;
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #263a43;
  outline-color: $color-primary;
}
.input-label {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #263a43;

  @include mq("phone-and-tablet") {
    font-size: 14px;
  }
}
// input label read only
.input-label-read-only {
  padding: 12px 15px 0;
  margin: 0;
  background-color: #fafafa;
  text-transform: capitalize;
  color: #000;
  font-family: "Monda", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  border-radius: 6px 6px 0 0;
  display: block;
  padding-bottom: 5px;

  @include mq("lap-to-small-desk") {
    font-size: 14px;
  }

  @include mq("phone-and-tablet") {
    padding: 8px 11px 0;
    font-size: 12px;

    @include mq("phone") {
      font-size: 10px;
    }
  }
}
// input read only
.input-tag[readonly] {
  font-family: "Monda", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border: none;
  padding: 0px 15px 12px;
  background-color: #fafafa;
  border-radius: 0 0 6px 6px;
  height: 30px;
  &::placeholder {
    color: #000;
  }
  &:focus {
    outline: none;
  }
  @include mq("mid-lap") {
    font-size: 14px;
  }
  @include mq("phone-and-tablet") {
    padding: 0 11px 8px;
    font-size: 12px;

    @include mq("phone") {
      font-size: 10px;
    }
  }
}
input[type="number"] {
  appearance: none;
  -webkit-appearance: none;
}

//input field when error
.input-wrapper,
.nin-input {
  &.error {
    .input-default,
    .normal-input {
      border: 1px solid $color-danger;
      &:focus {
        outline: none;
      }
    }
    .error-text {
      color: $color-danger;
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      padding-top: 5px;
      @include flexbox();
      @include align-items(center);
      .icon {
        @include inline-flex();
        margin-right: 4px;
      }
    }
  }
}
.input-wrapper {
  .error-text {
    display: none;
  }
}

.custom-radio,
.specialCare-check {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  background-color: none;
  margin: 0;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect x='0.3' y='0.3' width='23.4' height='23.4' rx='3.7' stroke='%237161EF' strokeWidth='0.6' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='.3' y='.3' width='15.4' height='15.4' rx='2.7' stroke='%237161EF'/%3E%3Cpath d='M13.286 4.841 6.233 12 3 8.719l.829-.841 2.404 2.434L12.457 4l.829.841Z' fill='%237161EF'/%3E%3C/svg%3E");
  }
}

// Button
.btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  padding: 12px 20px;
  border-radius: 6px;
  background: $color-primary;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-align: center;
  height: 40px;
  @apply bg-primary border border-primary shadow-buttonShadow rounded-md capitalize transition-all duration-300 hover:bg-white hover:text-primary;

  @include mq("phone-and-tablet") {
    padding: 8px 16px;
  }

  &:hover {
    svg {
      fill: $color-primary;
    }
  }
}
.icon-hover {
  &:hover {
    svg {
      fill: $color-primary;
    }
  }
}
.button {
  &:hover {
    svg {
      fill: $color-primary;
    }

    .btn-loader {
      &:after {
        border: 3px solid $color-primary;
        // border-top-color: ;
        border-top-color: rgba(113, 97, 239, 0.5);
      }
    }
  }
}

.rs-btn-primary {
  background-color: $color-primary;
  color: white;
  &.rs-btn-disabled {
    background-color: $color-primary;
  }
}
// For Loader
.loader {
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 50%;
  position: relative;
  animation: skLinRotate 1.5s ease-in-out infinite alternate;
  z-index: 100;

  &:after {
    content: "";
    position: absolute;
    inset: 5px;
    border-radius: 50%;
    // border: 5px solid rgba(0, 0, 0, 0.5);
    border: 3px solid $color-primary;
    border-top-color: rgba(113, 97, 239, 0.5);
  }
}
.btn-loader {
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
  animation: skLinRotate 1.5s ease-in-out infinite alternate;
  z-index: 100;

  &:after {
    content: "";
    position: absolute;
    inset: 2px;
    border-radius: 50%;
    border: 3px solid #fff;
    // border-top-color: $color-primary;
    border-top-color: rgba(255, 255, 255, 0.5);
  }
}
@keyframes skLinRotate {
  95%,
  100% {
    transform: rotate(840deg);
  }
}

// DatePicker

.datePicker {
  input {
    padding: 0px 16px;
    background: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 156.5%;
    outline: none;
    height: 40px;
    width: 100%;

    @include mq("phone-and-tablet") {
      height: 38px;
      padding: 0px 12px;
      font-size: 12px;
    }
  }
  .MuiStack-root {
    padding: 0;
    border: 1px solid $color-primary;
    height: 40px;
    overflow: hidden;
    border-radius: 6px;
    outline: none;
    width: 100%;
    max-width: 100%;

    @include mq("lap-and-up") {
      max-width: 295px;
    }
    @include mq("mid-lap") {
      max-width: 240px;
    }
    @include mq("tablet") {
      max-width: 200px;
    }

    @include mq("phone-and-tablet") {
      height: 40px;
    }

    .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0 !important;
    top: 50%;
    transform: translateY(-50%);
  }

  .react-datepicker__view-calendar-icon input {
    padding: 6px 10px !important;
  }
}

// Input Fields
.normal-input {
  border: 1px solid $color-primary;
  height: 40px;
  border-radius: 6px;
  padding: 8px 16px;
  outline: none;
  width: 100%;

  @include mq("phone-and-tablet") {
    padding: 6px 12px;
    height: 40px;
  }
  // @apply border !border-primary lg:h-[40px] h-[40px] rounded-[6px] lg:px-4 lg:py-2 px-3 py-1.5 w-full;
}

// ------------ Three dots Loading Animation ------------

.loading-animation {
  display: flex;
  align-items: center;
}

.dots {
  display: flex;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-primary;
  margin: 0 5px;
  opacity: 0;
  animation: dot-animation 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0.8s;
}

.dot:nth-child(2) {
  animation-delay: 1.4s;
}

.dot:nth-child(3) {
  animation-delay: 2s;
}

@keyframes dot-animation {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}

// MUI Calender Global CSS
.MuiDayCalendar-header {
  width: 100%;
  display: flex;
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  flex: 1;
  width: calc(100% / 7);
  text-align: center;
}

.Mui-selected {
  background-color: #7161ef !important;
}

// Skeleton
.skeleton-box {
  display: inline-block;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}


// Global Table Design

.primary-table {
  table {
    width: -webkit-fill-available;
    thead {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      td {
        padding: 10px 15px;
        font-size: 14px;
        white-space: nowrap;
        color: #263a43;
        font-weight: 500;

        @include mq("phone-and-tablet") {
          font-size: 12px;
          padding: 8px 12px;
        }
      }
    }
    tbody {
      td {
        padding: 10px 15px;
        font-size: 14px;
        color: #524f4f;

        @include mq("phone-and-tablet") {
          font-size: 12px;
          padding: 8px 12px;
        }
      }
    }
  }
}