@import "./mixin.scss";
@import "./variable.scss";

.toaster {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1000;
  pointer-events: none;
  width: fit-content;
  font-size: 12px;

  &.visible {
    opacity: 1;
    transform: translateX(-50%) translateY(-10px);
    pointer-events: auto;
  }

  &.success {
    background-color: #4caf50;
  }

  &.error {
    background-color: #f44336;
  }

  @include mq("phone-and-tablet") {
    padding: 10px 10px;
    font-size: 12px;
  }
}
