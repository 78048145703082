@import "../common/mixin.scss";
@import "../common/variable.scss";

.td-vaccination {
  .vaccination-table {
    table {
      width: -webkit-fill-available;
      thead {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        td {
          padding: 16px 20px;
          font-size: 16px;
          white-space: nowrap;
          color: #263a43;
          font-weight: 600;

          @include mq("phone-and-tablet") {
            font-size: 12px;
            padding: 12px 15px;
          }
        }
      }
      tbody {
        td {
          padding: 12px 20px;
          font-size: 14px;
          color: #524f4f;

          @include mq("phone-and-tablet") {
            font-size: 12px;
          }
        }
      }
    }
  }

  .dose-table {
    border-collapse: collapse;
    background: white;
    padding: 20px;

    table {
      text-align: center;
      border: 1px solid #758092;
      border-radius: 12px;
      background: white;
      width: 100%;
      color: #263a43;

      thead {
        th {
          font-size: 16px;
          padding: 10px;
          white-space: nowrap;
          border: 1px solid #758092;
          font-weight: 600;
        }
      }
      tbody {
        td {
          font-size: 14px;
          padding: 10px;
          white-space: nowrap;
          border: 1px solid #758092;
          font-weight: 400;
        }
      }
    }
  }
}
