@import "../common/mixin.scss";
@import "../common/variable.scss";

.account-logo{
  svg{
    @include mq("phone") {
      height: 60px;
    }
  }
}

// Register Page
.register-page {
  .choose-account {
    .account {
      .icon-shape {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='48' viewBox='0 0 50 48' fill='none'%3E%3Cpath d='M1.095 18.233 25 .865l23.905 17.368-9.131 28.101H10.226l-9.13-28.101Z' stroke='%237161EF' stroke-width='1.4'/%3E%3C/svg%3E%0A");
        width: 52px;
        height: 52px;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        svg {
          @include mq("phone") {
            width: 15px;
            height: 15px;
          }
          transition: 0.3s;
        }
      }
      &:hover,
      &.active {
        .icon-shape {
          background-image: url("data:image/svg+xml,%3Csvg width='50' height='48' viewBox='0 0 50 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m25 0 24.727 17.966-9.445 29.068H9.718L.273 17.966 25 0Z' fill='%237161EF'/%3E%3C/svg%3E");

          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}
