@import "./mixin.scss";
@import "./variable.scss";

.layout {
  //    --------- Sidebar Styling ---------
  .sidebar-wrapper {
    @include mq("phone-and-tablet") {
      position: fixed;
      height: 100%;
      transform: translateX(-100%);
      transition: 0.3s ease-in-out;

      &.open-menu {
        transform: translateX(0%);
      }

      .sidebar{
        height: 100%;
      }
    }

    .sidebar {
      border-radius: 0px 0px 30px 0px;
      background: #fff;

      .logo {
        @include mq("phone") {
          svg {
            height: 60px;
          }
        }
      }

      .menu-wrapper {
        .menu {
          @apply flex flex-col p-1.5 gap-3;

          @include mq("phone-and-tablet") {
            @apply gap-2;
          }

          .menu-link {
            position: relative;
            @apply lg:text-base text-sm text-gray font-normal transition-all duration-300 flex items-center;
            gap: 15px;
            padding: 13px 0 13px 15px;

            @include mq("phone-and-tablet") {
              padding: 8px 0 8px 10px;
            }

            svg {
              @apply transition-all duration-300 max-lg:w-5 max-lg:h-5;
            }

            &:hover,
            &.active {
              @apply text-primary;

              svg {
                fill: $color-primary;
              }
            }

            span {
              width: 22px;
              @apply flex justify-start;
            }
            &.active {
              &:before {
                content: "";
                width: 10px;
                height: 50px;
                background-color: $color-primary;
                border-radius: 50%;
                position: absolute;
                left: -11px;
                top: 0;

                @include mq("phone-and-tablet") {
                  height: 35px;
                }
              }
            }
          }
          .submenu {
            position: relative;
            .menu-link {
              @apply items-baseline py-3;

              @include mq("phone-and-tablet") {
                @apply py-2;
              }

              span {
                @apply justify-center;
              }
            }
          }
        }
      }
    }
  }

  .app-header {
    .headerHamburger {
      width: 25px;
      height: 2px;
      background-color: #000;
      border-radius: 10px;
      display: block;
      position: relative;
      top: 7px;
      transition: 0.3s;

      &:after,
      &:before {
        content: "";
        height: 2px;
        background-color: #000;
        border-radius: 10px;
        position: absolute;
        left: 0;
        transition: 0.3s;
      }

      &:after {
        width: 25px;
        top: -14px;
      }
      &:before {
        width: 20px;
        top: -7px;
        left: 5px;
      }

      &.active {
        transform: rotate(-45deg) translateY(-5px) translateX(5px);

        &:before {
          opacity: 0;
        }
        &:after {
          transform: rotate(90deg) translateX(14px);
        }
      }
    }
  }
}
