@import "../common/mixin.scss";
@import "../common/variable.scss";

.child-dashboard {
  .cards {
    @apply flex gap-3 flex-wrap;

    .card {
      // flex-basis: calc(33.33% - 8px);
      border-radius: 20px;
      border: 1px solid var(--Stroke-Color, #eff0f6);
      background: #fff;
      @apply flex flex-col items-center justify-center lg:min-h-[100px] lg:p-5 p-3 2xl:basis-[calc(50%-6px)] lg:basis-[calc(100%)] sm:basis-[calc(33.33%-8px)] xs:basis-[calc(50%-6px)] basis-[calc(100%)] text-center;

    }
  }
}
