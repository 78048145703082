/* MIXIN : MEDIA QUERIES
 *    EX : @include mq( 'lap-and-up' ) { CODE }
 * ------------------------------------------------------------------------- */
 $phone: "screen and (max-width: 767px)";
 $tablet-up: "screen and (min-width: 1025px)";
 $phone-and-tablet: "screen and (max-width: 1024px)";
 $tablet: "screen and (min-width: 768px) and (max-width: 1024px)";
 $lap-to-small-desk: "screen and (min-width: 1025px) and (max-width: 1279px)";
 $mid-lap: "screen and (min-width: 1025px) and (max-width: 1400px)";
 $lap-and-up: "screen and (min-width: 1400px)";
 
 @mixin mq($alias) {
   @if $alias == "phone" {
     @media #{$phone} {
       @content;
     }
   } @else if $alias == "phone-and-tablet" {
     @media #{$phone-and-tablet} {
       @content;
     }
   } @else if $alias == "tablet-up" {
     @media #{$tablet-up} {
       @content;
     }
   } @else if $alias == "mid-lap" {
     @media #{$mid-lap} {
       @content;
     }
   } @else if $alias == "tablet" {
     @media #{$tablet} {
       @content;
     }
   } @else if $alias == "lap-and-up" {
     @media #{$lap-and-up} {
       @content;
     }
   } @else if $alias == "lap-to-small-desk" {
     @media #{$lap-to-small-desk} {
       @content;
     }
   } @else {
     @media (#{$alias}) {
       @content;
     }
   }
 }
 
 /* MIXIN : MIXIN
  *    EX : @include All Mixin
  * ------------------------------------------------------------------------- */
 @mixin flexbox() {
   display: -webkit-box;
   display: -moz-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
 }
 @mixin flex($content) {
   -webkit-box-flex: $content;
   -ms-flex: $content;
   flex: $content;
 }
 @mixin flex-direction($direction) {
   flex-direction: $direction;
   -webkit-flex-direction: $direction;
 }
 @mixin flex-wrap($wrap) {
   flex-wrap: $wrap;
   -webkit-flex-wrap: $wrap;
 }
 @mixin justify-content($content) {
   -webkit-justify-content: $content;
   justify-content: $content;
 }
 @mixin flex-grow($content) {
   -webkit-box-flex: $content;
   -ms-flex-positive: $content;
   flex-grow: $content;
 }
 @mixin flex-shrink($content) {
   -ms-flex-negative: $content;
   flex-shrink: $content;
 }
 @mixin flex-flow($content) {
   -webkit-box-orient: $content;
   -webkit-box-direction: $content;
   -ms-flex-flow: $content;
   flex-flow: $content;
 }
 @mixin align-content($content) {
   -ms-flex-line-pack: $content;
   align-content: $content;
 }
 @mixin order($content) {
   -webkit-box-ordinal-group: $content;
   -ms-flex-order: $content;
   order: $content;
 }
 @mixin align-items($align) {
   -webkit-box-align: $align;
   -webkit-flex-align: $align;
   -ms-flex-align: $align;
   -webkit-align-items: $align;
   align-items: $align;
 }
 @mixin align-self($content) {
   -ms-flex-item-align: $content;
   -ms-grid-row-align: $content;
   align-self: $content;
 }
 @mixin inline-flex() {
   display: -webkit-inline-box;
   display: -ms-inline-flexbox;
   display: inline-flex;
 }
 @mixin grid() {
   display: -ms-grid;
   display: grid;
 }
 @mixin grid-template-columns($content) {
   -ms-grid-columns: $content;
   grid-template-columns: $content;
 }
 @mixin grid-template($content) {
   -ms-grid-rows: $content;
   -ms-grid-columns: $content;
   grid-template: $content;
 }
 @mixin grid-template-rows($content) {
   -ms-grid-rows: $content;
   grid-template-rows: $content;
 }
 @mixin grid-row-start($content) {
   -ms-grid-row: $content;
   grid-row-start: $content;
 }
 @mixin grid-row-end($content) {
   -ms-grid-row-span: $content;
   grid-row-end: $content;
 }
 @mixin user-select() {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 @mixin line-clamp($content) {
   -webkit-line-clamp: $content;
 }
 @mixin box-orient($content) {
   -webkit-box-orient: $content;
 }
 @mixin transform($transforms) {
   -moz-transform: $transforms;
   -o-transform: $transforms;
   -ms-transform: $transforms;
   -webkit-transform: $transforms;
   transform: $transforms;
 }
 @mixin transition($x...) {
   -webkit-transition: $x;
   -moz-transition: $x;
   -ms-transition: $x;
   -o-transition: $x;
   transition: $x;
 }
 @mixin opacity($value) {
   $IEValue: $value * 100;
   opacity: $value;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=" + $IEValue + ")";
   filter: alpha(opacity=$IEValue);
 }
 @mixin keyframes($animationName) {
   @-webkit-keyframes #{$animationName} {
     @content;
   }
   @-moz-keyframes #{$animationName} {
     @content;
   }
   @-o-keyframes #{$animationName} {
     @content;
   }
   @keyframes #{$animationName} {
     @content;
   }
 }
 @mixin background-opacity($color, $opacity: 0.5) {
   background: $color;
   background: rgba($color, $opacity);
 }
 @mixin font_size($sizeValue: 1.6) {
   font-size: ($sizeValue * 10) + px;
   font-size: $sizeValue + rem;
 }
 @mixin box-shadow($shadow...) {
   -webkit-box-shadow: $shadow;
   -moz-box-shadow: $shadow;
   box-shadow: $shadow;
 }
 @mixin border-radius($radius) {
   -webkit-border-radius: $radius;
   -moz-border-radius: $radius;
   border-radius: $radius;
 }
 @mixin object-fit($content) {
   -o-object-fit: cover;
   object-fit: cover;
 }
 @mixin object-position($content) {
   -o-object-position: $content;
   object-position: $content;
 }
 
 /* MIXIN : WIDTH/HEIGHT
  *    EX : @include square(100px, 100px);
            You can pass width && height or just pass width and the height will default to the width value 
  * ---------------------------------------------------------------------------------------------------------*/
 @mixin square($width, $height: $width) {
   width: $width;
   height: $height;
 }
 
 /* MIXIN : LINEAR GRADIENT
  *    EX : @include linear-gradient((90deg, #000 0%, rgba(0,0,0,0) 100%));
  * ------------------------------------------------------------------------- */
 @mixin linear-gradient($value) {
   background: -webkit-linear-gradient($value);
   background: -moz-linear-gradient($value);
   background: -o-linear-gradient($value);
 }
 