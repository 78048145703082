@import "../common/mixin.scss";
@import "../common/variable.scss";

.child-vaccination {
  .vaccination-table {
    table {
      width: -webkit-fill-available;
      thead {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        td {
          padding: 16px 20px;
          font-size: 16px;
          white-space: nowrap;
          color: #263a43;
          font-weight: 600;

          @include mq("phone-and-tablet") {
            font-size: 12px;
            padding: 12px 15px;
          }
        }
      }
      tbody {
        td {
          padding: 12px 20px;
          font-size: 14px;
          color: #524f4f;

          @include mq("phone-and-tablet") {
            font-size: 12px;
          }
        }
      }
    }
  }

  .calender-wrapper {
    width: 100%;

    .DateCalendarServerRequest {
      width: 100%;

      .DateCalendar {
        width: 100%;
        .PickersDay {
          display: flex;
          justify-content: center;
          width: calc(100% / 7);
        }

        .DayCalendarSkeleton {
          width: 100%;
        }
      }
    }
  }

  .last-visit-table {
    table {
      font-size: 10px;
      font-weight: 500;
    }
    td {
      font-size: 10px;
      font-weight: 500;
      padding: 4px;
      text-align: center;
    }
  }
}
